import * as React from "react"
import { Row, Col } from "react-grid-system";
import { Link } from "gatsby"
import HcpLayout from "../components/patient/layout";

/* const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
} */

const NotFoundPage = () => {
  return (
    <HcpLayout pageClass="hcp-page-not-found">
      <Row>
        <Col xs={12}>
            <h1 className="h1 first">Page Not Found</h1>
            <p>
              Sorry 😔, we couldn’t find what you were looking for. Please check the <Link to="/site-map">sitemap</Link>.
              {/* <br />
              {process.env.NODE_ENV === "development" ? (
                <>
                  <br />
                  Try creating a page in <code style={codeStyles}>src/pages/</code>.
                  <br />
                </>
              ) : null}
              <br /> */}
              </p>

        </Col>
      </Row>
    </HcpLayout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
